<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormFile, BProgress } from 'bootstrap-vue'
export default {
    components: {
        BCard, BButton, BModal, BFormFile, BProgress
    },
    data() {
        return {
            pageArrayStock: [], // for Frontend Search
            pageArray: [],

            addModal: false,
            editObject: {
                noticeTitle: '',
                noticeDescription: '',
                noticeImage: '',
            },

            filterTitle: '',
            filterDescription: '',

            mediaModal: false,
            viewMedia: '',
            viewMediaType: '',
            imageView: '',

            percentValue: 0
        }
    },
    async created() {
        await this.mountedData()
    },
    methods: {
        async mountedData() {

            // Clear Variables..
            this.addModal = false
            this.mediaModal = false
            this.imageView = ''
            this.filterTitle = ''
            this.percentValue = 0

            // Get List
            await this.$http.get('features/notice/list')
                .then(response => {
                    this.pageArrayStock = this.pageArray = response.data
                }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
        },
        async tableInteractive(item, index, type) {
            if (type === 'edit') {
                this.editObject = structuredClone(item)
                this.addModal = true
            }else{
                this.editObject = structuredClone(item)
            }
        },
        async saveData(type, item) {
            if (type === 'create') {
                const formData = new FormData()
                formData.append('noticeTitle', this.editObject.noticeTitle)
                formData.append('noticeDescription', this.editObject.noticeDescription)
                formData.append('noticeImage', this.editObject.noticeImage)

                const axiosConfig = {
                    onUploadProgress: progressEvent => {
                        this.percentValue = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    }
                }

                await this.$http({
                    method: 'POST',
                    url: 'features/notice/create',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        this.percentValue = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    },
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success, Please Wait..',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.mountedData()
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ERROR',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
            if (type === 'delete') {
                await this.$http({
                    url: 'features/notice/delete',
                    method: 'DELETE',
                    data: {
                        noticeId: this.editObject.noticeId
                    },
                    headers: {
                        "Content-Type": "application/json;charset=utf-8"
                    }
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success, Please Wait..',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.mountedData()
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ERROR',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
        },
        async filterNow() {
            this.pageArray = this.pageArrayStock.filter(x => x.noticeTitle.toString().toLocaleLowerCase().includes(this.filterTitle.toLocaleLowerCase()) && x.noticeDescription.toString().toLocaleLowerCase().includes(this.filterDescription.toLocaleLowerCase()))
        },
        openMedia(mediaUrl) {
            this.viewMedia = mediaUrl
            this.mediaModal = true
        },
        imagePreview(event) {
            const fileReader = new FileReader()
            fileReader.onload = () => {
                this.imageView = fileReader.result
            }
            fileReader.readAsDataURL(event.target.files[0])
            this.editObject.noticeImage = event.target.files[0]
        },
        deleteImage() {
            this.editObject.noticeImage = ''
        }
    },
    watch: {
        filterTitle: {
            handler() {
                this.filterNow()
            }
        },
        filterDescription: {
            handler() {
                this.filterNow()
            }
        },
    }
}
</script>
        
<template>
    <section class="pb-2">
        <div class="tb-card tb-flex-vertical">
            <a class="tb-page-title">Registered Users ({{  pageArrayStock.length  }})</a>
            <button class="tb-button bg-primary ml-auto" @click="addModal = true">
                <a class="text-white">Create Notice</a>
            </button>
        </div>
        <div class="tb-card mt-2">
            <table class="tb-table">
                <thead>
                    <tr>
                        <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly>
                        </th>
                        <th style="width: 20%"><input type="text" class="form-control" placeholder="Notice Image"
                                disabled readonly></th>
                        <th style="width: 30%"><input type="text" class="form-control" placeholder="Notice Title"
                                v-model="filterTitle"></th>
                        <th style="width: 30%"><input type="text" class="form-control" placeholder="Notice Description"
                                v-model="filterDescription"></th>
                        <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled
                                readonly></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in pageArray">
                        <td><a>{{  index + 1  }}</a></td>
                        <td>

                            <img :src="item.noticeImage"
                                class="w-100 h-120-px tb-img-cover tb-border-radius-0-5-rem tb-hover-pointer" alt=""
                                @click="openMedia(item.noticeImage)">
                        </td>
                        <td><a>{{  item.noticeTitle  }}</a></td>
                        <td><a>{{  item.noticeDescription  }}</a></td>
                        <td>
                            <button class="tb-button bg-danger" @click="$bvModal.show('bv-modal-notice'), tableInteractive(item, 'delete')">
                                <span class="text-white">Delete</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Edit Modal -->
        <b-modal v-model="addModal" hide-header hide-footer centered size="lg">
            <div class="row py-1">
                <div class="col-12 text-center">
                    <a class="tb-page-title">Create Notice</a>
                    <hr>
                </div>
                <div class="col-12">
                    <label>Notice Title</label>
                    <input type="text" class="form-control" v-model="editObject.noticeTitle">
                </div>
                <div class="col-12">
                    <label>Notice Description</label>
                    <input type="text" class="form-control" v-model="editObject.noticeDescription">
                </div>
                <div class="col-12 mt-1">
                    <label>Notice Image Url</label>
                    <b-form-file @change="imagePreview" accept=".jpg, .jpeg, .png, .mp4, .JPEG, .PNG, .JPG, .MP4"
                        placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                    <div v-if="imageView" class="col-12 mt-2 px-4">
                        <img class="img-fluid tb-radius-5 shadow" :src="imageView" alt="">
                        <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage()">Delete</button>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <hr>
                    <b-progress max="100" :value="percentValue" />
                    <hr>
                    <b-button variant="outline-primary" class="mt-1" @click="saveData('create')">Create & Close
                    </b-button>
                </div>
            </div>
        </b-modal>

        <!-- Media Modal -->
        <b-modal v-model="mediaModal" hide-header hide-footer centered size="lg">
            <div class="row py-1">
                <div class="col-12 text-center">
                    <img :src="viewMedia" class="w-100 img-fluid" alt="">
                </div>
            </div>
        </b-modal>

        <b-modal centered id="bv-modal-notice" hide-header hide-footer>
            <h3 class="text-center mt-2">
                {{editObject.noticeTitle}}
            </h3>
            <div class="d-block text-center mt-2">
                Are you sure you want to delete this Notice ?
            </div>
            <div class="col-12 d-flex">
                <b-button class="mt-3 btn-danger mx-1" block @click="$bvModal.hide('bv-modal-notice'), saveData('delete')">Delete
                </b-button>
                <b-button class="mt-3 mx-1" block @click="$bvModal.hide('bv-modal-notice')">Cancel
                </b-button>
            </div>
        </b-modal>

    </section>
</template>